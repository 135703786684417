<template>
  <div>
    <div class="banner">
      <div class="position-relative" style="z-index:1">
        <div class="subtitle7 info">소스 스토어</div>
        <div class="mo-section-title-bold main margin-top-8">대표님, 예산을 들여 제작했던<br>결과물을 운영하지 않고<br>묵혀두고 계신가요?<br>
          예비창업팀에게 공유하고<br>수익창출 하세요</div>
        <div class="body4 sub2 margin-top-20">프리랜서 혹은 인하우스 디자이너, 개발자를 위한 소스 스토어입니다.
          기제작된 결과물을 기반으로 시작하니 빠르고 효율적으로 제작할 수 있습니다.<br>
          창업팀이 디자인을 구매해서 플랫폼파이로 맞춤제작 신청하는 것 또한 가능해요!</div>
        <button class="button is-dark body4-medium margin-top-40" style="height:40px;padding:8px 24px"
                @click="routerPush('/template_inquiry')">내 소스 파일 판매 신청</button>
      </div>
    </div>

    <div class="h8 main" style="padding:24px 16px 12px 16px">소스 스토어</div>

    <div v-for="(card, idx) in templates" :key="`card-${card.id}`">
      <div class="gap" v-if="idx>0"></div>
      <card-product :card="card" style="padding:16px"></card-product>
    </div>
    <div style="padding:16px" v-if="page_num < total_page">
      <button class="button is-gray button-more body2-medium" @click="clickMore">더보기</button>
    </div>
  </div>
</template>

<script>
  import CardProduct from "../../../component/CardProduct";
  export default {
    name: "TemplatesMobile",
    components: {CardProduct},
    created() {
      this.getData();
    },
    data() {
      return {
        page_num: 1,
        total_page: 1,
        templates: []
      }
    },
    methods: {
      getData() {
        let params = {
          category: 61,
          fields: 'id,name,simple_desc,price,img,partner.img,partner.name,category1,category2,preview_url,visit,rate,params',
          page_num: this.page_num,
          page_length: 10,
          ordering: '-created_time'
        };
        this.$axios.get('user/0/mapping/product', {params: params}).then(res => {
          this.templates = res.data.data;
          this.total_page = res.data.total_page;
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .banner
    background-color $subcolor50
    padding 40px 20px
    overflow hidden
    position relative
    text-align center

  .grid-banner
    position relative
    display grid
    gap 60px
    grid-template-columns 1fr 502px

  .circle
    position absolute
    width 356px
    height 356px
    left calc(50% - 80px)
    top calc(50% - 38px)
    background #00B2FF
    opacity 0.5
    filter blur(200px)
    z-index 0
</style>
